import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import styles from "./Subtitles.module.scss";
import { useAuth } from "../../contexts/AuthContext";
import { ReactMic } from "../react-mic/src";
import apiSocket from "../../services/socket";

const API_KEY = "IUvBk9zz5hOAAhhfRtkhbtFe7PSAvaAi";

export default function Subtitles({ device, language }) {
  const [recognize, setRecognize] = useState(false);
  const [recording, setRecording] = useState(false);
  const { user } = useAuth();
  const userId = useMemo(() => user?.id, [user]);
  const endAnchor = useRef(null);
  const [result, setResult] = useState({ transcript: "", language: "" });
  const [newLine, setNewLine] = useState(null);
  const [lines, setLines] = useState([]);
  const smSocket = useRef({});
  const buffer = useRef(new Blob());

  const pushLine = useCallback(
    (text, userId, userName) => {
      const newLines = [...lines];
      if (lines.length > 0 && newLines[lines.length - 1].userId === userId) {
        newLines[lines.length - 1].text += " " + text;
      } else {
        newLines.push({
          text: `${text}`,
          userId,
        });
      }
      setLines(newLines.slice(-20));
    },
    [lines, setLines]
  );

  useEffect(() => {
    if (!newLine) return;
    pushLine(newLine.text, newLine.userId, newLine.userName);
  }, [newLine]);

  useEffect(() => {
    endAnchor.current?.scrollIntoView({ behavior: "smooth" });
  }, [lines]);

  const initSmSocket = async () => {
    try {
      const res = await fetch(
        "https://mp.speechmatics.com/v1/api_keys?type=rt",
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${API_KEY}`,
          },
          body: JSON.stringify({
            ttl: 3600,
          }),
        }
      );
      const { key_value } = await res.json();

      console.log("key_value", key_value);
      smSocket.current = new WebSocket(
        `wss://eu2.rt.speechmatics.com/v2/${language}?jwt=${key_value}`
      );
      smSocket.current.onerror = (event) => {
        console.log("socket error", event);
      };
      smSocket.current.onclose = (event) => {
        console.log("socket closed", event);
        // setTimeout(() => window.location.reload(), 2000);
      };
      smSocket.current.onopen = (event) => {
        console.log("socket open", event);
        smSocket.current.send(
          JSON.stringify({
            message: "StartRecognition",
            audio_format: {
              type: "raw",
              encoding: "pcm_s16le",
              sample_rate: 48000,
            },
            transcription_config: {
              language: language,
              max_delay: 5,
              max_delay_mode: "flexible",
            },
          })
        );
      };

      smSocket.current.addEventListener("message", (event) => {
        const data = JSON.parse(event.data);
        if (data.message === "AddTranscript") {
          const { transcript } = data.metadata;
          if (transcript) {
            setResult({ transcript, language });
          }
        }
      });
    } catch (err) {
      console.log("Error initSmSocket", err);
    }
  };

  const isSocketOpen = (socket) => {
    return socket !== null && socket !== undefined && socket.readyState === 1;
  };

  useEffect(() => {
    if (language) {
      setRecording(true);
      initSmSocket();
    } else {
      setRecording(false);
    }
  }, [language]);

  useEffect(() => {
    apiSocket.on("messages:add", (data) => {
      setNewLine({
        text: data.text,
        userId: data.userId,
        userName: data.userName,
      });
    });
    return () => {
      apiSocket.removeListener("messages:add");
      return null;
    };
  }, []);

  const translateResult = async (result) => {
    const { transcript: text, language } = result;
    if (!text) return;

    apiSocket.emit("messages:add", { text });

    setNewLine({
      text,
      userId: user?.id,
      userName: `${user?.firstname} ${user?.lastname}`,
    });
  };

  useEffect(() => {
    translateResult(result);
  }, [result]);

  useEffect(() => {
    if (recognize) {
      recognizeData();
      setRecognize(false);
    }
  }, [recognize]);

  const onData = (data) => {
    buffer.current = new Blob([buffer.current, data]);
    if (buffer.current.size >= 16384) {
      setRecognize(true);
    }
  };

  const recognizeData = async () => {
    if (isSocketOpen(smSocket.current)) {
      const data = buffer.current;
      const chunk = data.slice(0, 16384);
      buffer.current = data.slice(16384);
      const rawData = await chunk.arrayBuffer();
      smSocket.current.send(rawData);
    }
  };

  return (
    <div className={styles.container}>
      <ReactMic
        record={recording}
        className={styles.reactMic}
        onData={onData}
        strokeColor="blue"
        backgroundColor="white"
        bufferSize={512}
        mimeType="audio/wav"
        deviceId={device || undefined}
        channelCount={2}
        echoCancellation={true}
        autoGainControl={true}
        noiseSuppression={true}
        sampleRate={48000}
      />
      {lines.map((l, i) => (
        <p
          key={i}
          className={
            styles.text +
            " " +
            (l.userId === userId ? styles.color0 : styles.color0)
          }
        >
          {l.text}
        </p>
      ))}
      <div ref={endAnchor} />
    </div>
  );
}
